import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    this.element.addEventListener("wheel", (e) => e.preventDefault())
  }

  preventSubmit(event) {
    if (event.key === "Enter") {
      event.preventDefault()
    }
  }
}
