import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    selector: String
  }

  show(event) {
    if (!this.targetElement) { return }

    this.targetElement.classList.add("element--on")
  }

  dismiss(event) {
    if (!this.targetElement) { return }

    this.targetElement.classList.remove("element--on")
  }

  toggle(event) {
    event.preventDefault()

    if (!this.targetElement) { return }

    if (this.targetElement.classList.contains("element--on")) {
      this.dismiss(event)
    } else {
      this.show(event)
    }
  }

  get targetElement() {
    return document.querySelector(this.selectorValue)
  }
}
