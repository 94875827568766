import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["upload", "picker"]
  static classes = ["hideUpload", "hidePicker"]

  hidePicker(event) {
    event.preventDefault()

    if (this.hasUploadTarget && this.hasPickerTarget) {
      this.pickerTarget.classList.add(this.hidePickerClass)
      this.uploadTarget.classList.remove(this.hideUploadClass)
    }
  }

  showPicker(event) {
    event.preventDefault()

    if (this.hasUploadTarget && this.hasPickerTarget) {
      this.pickerTarget.classList.remove(this.hidePickerClass)
      this.uploadTarget.classList.add(this.hideUploadClass)
    }
  }
}
