import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["label"]
  static values = {
    disabledClassName: String,
    iconOnly: Boolean
  }

  connect() {
    const connectEvent = new CustomEvent("push-button:connect", { detail: { controller: this } })

    this.element.dispatchEvent(connectEvent)
  }

  disable() {
    this.element.classList.add(this.disabledClassNameValue)
  }

  enable() {
    this.element.classList.remove(this.disabledClassNameValue)
  }

  toggleDisabled(statement = undefined) {
    if (statement === undefined) {
      this.element.classList.toggle(this.disabledClassNameValue)
    } else if (statement) {
      this.disable()
    } else {
      this.enable()
    }
  }

  set text(text) {
    if (!this.hasLabelTarget) return

    this.element.setAttribute("title", text)
    this.labelTarget.textContent = text
  }

  get text() {
    if (!this.hasLabelTarget) return ""

    return this.labelTarget.textContent.trim()
  }

  get isIconOnly() {
    return this.iconOnlyValue
  }
}
