import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "addressField",
    "city",
    "state",
    "zip",
    "line1",
    "line2",
    "manualAddress",
    "googleAddress",
    "googleAddressButton",
    "manualAddressButton"
  ]
  static values = { countryCode: String }

  connect() {
    this.handleInitMap()

    // Refocus the address field make sure pac-container is placed correctly
    setTimeout(() => {
      this.addressFieldTarget.blur()
      this.addressFieldTarget.focus()
    }, 500)
  }

  placePacContainer(e) {
    e.currentTarget.parentNode.insertBefore(
      document.querySelector(".pac-container"),
      e.currentTarget.nextSibling
    )
  }

  handleInitMap() {
    const country = {
      componentRestrictions: { country: this.countryCodeValue },
    }
    this.autocomplete = new google.maps.places.Autocomplete(
      this.addressFieldTarget,
      country
    )

    this.autocomplete.setTypes(["address"])

    this.autocomplete.addListener(
      "place_changed",
      this.placeChanged.bind(this)
    )
  }

  placeChanged() {
    const place = this.autocomplete.getPlace()
    const line2Components = ["premise", "subpremise"]

    this.line1Target.value = place.name
    this.line2Target.value = line2Components.map((type) => ( place.address_components.find((c) => c.types.includes(type))?.long_name || "")).filter(Boolean)
    this.cityTarget.value = place.address_components.find((c) => (c.types.includes("locality") || c.types.includes("postal_town")))?.long_name || ""
    this.stateTarget.value = place.address_components.find((c) => (c.types.includes("administrative_area_level_2") || c.types.includes("administrative_area_level_1")))?.long_name || ""
    this.zipTarget.value = place.address_components.find((c) => c.types.includes("postal_code"))?.long_name || ""
  }

  toggleAddressFields(e) {
    e.preventDefault()

    const isManualAddressHidden = this.manualAddressTarget.classList.contains("hide")

    this.manualAddressTarget.classList.toggle("hide", !isManualAddressHidden)
    this.googleAddressTarget.classList.toggle("hide", isManualAddressHidden)
    this.googleAddressButtonTarget.classList.toggle("button--hide", !isManualAddressHidden)
    this.manualAddressButtonTarget.classList.toggle("button--hide", isManualAddressHidden)
  }
}
