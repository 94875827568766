import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "latInput",
    "longInput",

    "container",
  ]

  static values = {
    lat : String,
    long : String,
  }

  connect() {
    let zoom = 2
    let lat = 55.378051
    let lng = -3.435973

    if (this.latInputTarget.value !== "" && this.longInputTarget.value !== "") {
      lat = this.latInputTarget.value
      lng = this.longInputTarget.value
      zoom = 14
    }

    /* disabling eslint here due to mapboxgl not being bundled */
    /* eslint-disable */
    this.map = new mapboxgl.Map({
      container: "edit_google_map",
      center: new mapboxgl.LngLat(lng, lat),
      style: "https://api.maptiler.com/maps/basic/style.json?key=AUqGbDDrd3lWF7lPdu0g",
      zoom: zoom
    })

    const nav = new mapboxgl.NavigationControl({showCompass: false})

    this.map.addControl(nav, "top-left")
    this.map.on("click", (evt) => this.addMarker(evt))

    this.marker = new mapboxgl.Marker({
      element: this.containerTarget,
      offset: {x: 0, y: -40}
    }).setLngLat([lng, lat])
      .addTo(this.map)

    /* eslint-enable */
  }

  addMarker(evt) {
    const ll = evt.lngLat

    this.latInputTarget.value = ll.lat
    this.longInputTarget.value = ll.lng

    this.marker.setLngLat(ll.toArray()).addTo(this.map)
  }
}
