import { Controller } from "stimulus"
import Moment from "moment-timezone"
import "moment/locale/en-gb"
import "moment/locale/fr"
import "moment/locale/de"
import "moment/locale/es"
import "moment/locale/it"
import "moment/locale/nl"
import "moment/locale/pt"
import "moment/locale/tr"
import "moment/locale/el"
import "moment/locale/pl"
import "moment/locale/cs"
import { extendMoment } from "moment-range"

export default class extends Controller {
  static values = {
    fetchUrlOnLoad: String,
    fetchUrlOnLoadMethod: String,
    timezone: String,
    locale: String,
    clockType: String
  }

  static targets = [
    "sidebar",
    "sidebarToggler"
  ]

  connect() {
    global.timezone = this.timezone
    global.locale = this.locale

    this.prepareJavascriptTestCookie()
    this.prepareDialogSupport()
    this.prepareMomentObject()
    this.prepareBreakpoints()
    this.prepareIntercom()

    if (this.fetchUrlOnLoadValue) this.loadFetchUrl()

    // Ref: https://stackoverflow.com/a/30303898
    if (matchMedia("(hover: none)").matches) { // remove all the :hover stylesheets
      this.removeHoverStyles()
    }
  }

  toggleSidebar(event) {
    if (event) event.preventDefault()

    this.sidebarTarget.dataset.visible = !this.isSidebarVisible
    this.sidebarTogglerTarget.classList.toggle("tab__link--active", this.isSidebarVisible)
    document.body.classList.toggle("element--sidebar-visible", this.isSidebarVisible)
  }

  dismissSidebar(event) {
    if (this.isSidebarVisible) this.toggleSidebar()
  }

  switchToToday(event) {
    if (global.timetableController?.calendarController == null) return

    event.preventDefault()

    global.timetableController?.calendarController?.switchToToday(event)
  }

  removeHoverStyles() {
    try { // prevent exception on browsers not supporting DOM styleSheets properly
      for (const si in document.styleSheets) {
        const styleSheet = document.styleSheets[si]
        let rules = null
        // Some stylesheets are not from the same domain and we can't access their rules
        try {
          rules = styleSheet.cssRules || styleSheet.rules
        } catch (ex) {
          console.log(ex)
        }
        if (!rules) continue

        // We start from behind so that the indices don't change as we remove rules
        for (let ri = rules.length - 1; ri >= 0; ri--) {
          const rule = rules[ri]
          if (!rule.selectorText) continue

          if (rule.selectorText.match(":hover")) {
            // NOTE: We may want to enable adding back these styles if media query changes
            // To do this we'll add `change` event listener to the `matchMedia`'s result.
            // Not doing it now as the only usage seems to be testing while development and
            // I don't want to make this code more complicated unless it's really needed.
            const selectorWithoutHover = rule.selectorText.split(",").filter((s) => !s.match(":hover")).join(", ")
            // There are other selectors besides the hover text e.g. `a, a:hover`, which it converts to `a`
            if (selectorWithoutHover !== "") {
              rule.selectorText = selectorWithoutHover
              // There is only the hover selector
            } else {
              styleSheet.deleteRule(ri)
            }
          }
        }
      }
    } catch (ex) {
      console.log(ex)
    }
  }

  loadFetchUrl() {
    const method = this.fetchUrlOnLoadMethodValue || "get"

    Rails.ajax({
      type: method,
      dataType: "script",
      url: this.fetchUrlOnLoadValue
    })
  }

  prepareJavascriptTestCookie() {
    // Sometimes we want to know on the backend whether the JS has run. This is useful for excluding bots.

    const expires = new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toUTCString()
    document.cookie = "javascript_can_set_cookie=true; expires=" + expires + "; path=/"
  }

  prepareDialogSupport() {
    if (typeof HTMLDialogElement === "function") {
      this.element.classList.add("element--supports-dialog")
    }
  }

  prepareMomentObject() {
    const momentLocale = this.locale === "en" ? "en-gb" : this.locale

    global.timeFormat = this.timeFormat
    global.clockType = this.clockType
    global.moment = extendMoment(Moment)
    global.moment.tz.setDefault(this.timezone)
    global.moment.locale(momentLocale)
  }

  prepareBreakpoints() {
    const getBreakpoint = (breakpoint, rangeType) => {
      return parseInt(
        getComputedStyle(
          document.documentElement
        ).getPropertyValue(
          `--break-point-${breakpoint}-${rangeType}`
        )
      )
    }

    global.breakpoints = {
      tiny: {
        start: getBreakpoint("tiny", "start"),
        end: getBreakpoint("tiny", "end")
      },
      small: {
        start: getBreakpoint("small", "start"),
        end: getBreakpoint("small", "end")
      },
      medium: {
        start: getBreakpoint("medium", "start"),
        end: getBreakpoint("medium", "end")
      },
      large: {
        start: getBreakpoint("large", "start"),
        end: getBreakpoint("large", "end")
      },
      huge: {
        start: getBreakpoint("huge", "start"),
        end: getBreakpoint("huge", "end")
      }
    }
  }

  prepareIntercom() {
    if (!global.Intercom) return

    global.Intercom("onUnreadCountChange", function(unreadCount) {
      const intercomBadgeEvent = new CustomEvent("badge-indicator:change", {
        detail: { count: unreadCount, targetId: "intercom" }
      })
      window.dispatchEvent(intercomBadgeEvent)
    })
  }

  get timezone() {
    return this.timezoneValue || "Europe/London"
  }

  get locale() {
    const defaultLocale = "en"
    const locale = this.localeValue.split("-")[0]

    if (locale === "en") return this.localeValue

    return locale || defaultLocale
  }

  get clockType() {
    return this.clockTypeValue || "24_hours"
  }

  get timeFormat() {
    return this.clockType === "24_hours" ? "HH:mm" : "h:mma"
  }

  get isSidebarVisible() {
    return this.sidebarTarget.dataset.visible === "true"
  }
}
