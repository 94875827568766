import { Controller } from "stimulus"

export default class extends Controller {
  static values = { id: String }
  static targets = ["email", "phone"]

  initialize() {
    this.gtag("js", new Date())
    this.gtag("config", this.idValue, {"allow_enhanced_conversions": true})
  }

  signup(event) {
    const [data, ,] = event.detail

    const isSuccess = typeof data === "string"
    if (isSuccess) {
      const signUpEvent = {
        email: this.emailTarget.value,
        "phone_number": this.phoneTarget.value
      }

      this.gtag("event", "conversion", signUpEvent)
    }
  }

  gtag() {
    this.dataLayer.push(arguments)
  }

  get dataLayer() {
    window.dataLayer = window.dataLayer || []
    return window.dataLayer
  }
}
