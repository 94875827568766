import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["cta"]
  static values = {
    mainCtaSelector: String,
    showOnDesktop: Boolean
  }

  connect() {
    // With timeout we can be certain that any css/image loading stuff has happened and we are
    // at the correct scroll position.
    setTimeout(() => this.onScroll(), 100)
  }

  onScroll() {
    if (this.mainCta() === null) {
      console.log("Sticky CTA: cannot find mainCTA")
      return
    }
    if (this.isScrolledIntoView(this.mainCta()) || this.hideOnDesktop()) {
      this.hideCTA()
    } else {
      this.showCTA()
    }
  }

  hideCTA() {
    this.ctaTarget.style.display = "none"
  }

  showCTA() {
    this.ctaTarget.style.display = "block"
  }

  mainCta() {
    return document.querySelector(this.mainCtaSelectorValue)
  }

  isScrolledIntoView(el) {
    var rect = el.getBoundingClientRect()
    var elemTop = rect.top
    var elemBottom = rect.bottom

    // Only completely visible elements return true:
    var isVisible = (elemTop >= 0) && (elemBottom <= window.innerHeight)
    // Partially visible elements return true:
    //isVisible = elemTop < window.innerHeight && elemBottom >= 0
    return isVisible
  }

  hideOnDesktop() {
    return this.desktopViewPort() && !this.showOnDesktopValue
  }

  desktopViewPort() {
    return window.innerWidth > 768
  }
}
