import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "day",
    "summaryInput"
  ]

  static values = {
    allowBreaks: Boolean
  }

  connect() {
    this.extractSummary()
  }

  extractSummary() {
    const summary = []

    this.dayTargets.forEach(el => {
      const data = {
        "isActive": el.querySelector("input[type=\"checkbox\"").checked,
        "timeFrom": el.querySelector("input.work_from").value || null,
        "timeTill": el.querySelector("input.work_till").value || null,
      }

      if (this.allowBreaksValue) {
        data["has_lunch_break"] = el.dataset.businessDayHasBreakValue
        data["lunch_break_starts_at"] = el.querySelector("input.break_from").value
        data["lunch_break_finishes_at"] = el.querySelector("input.break_till").value
      }

      summary.push(data)
    })

    this.summaryInputTarget.value = JSON.stringify(summary)
  }
}
