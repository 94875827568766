import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import "shared"
import "helpers"
import "dialog-polyfill/dist/dialog-polyfill.css"

const application = Application.start()
const sharedContext = require.context("controllers/shared", true, /_controller\.js$/)
const siteContext = require.context("controllers/site", true, /_controller\.js$/)

application.load(definitionsFromContext(sharedContext))
application.load(definitionsFromContext(siteContext))

global.application = application
global.state = {
  flashMessages: []
}
