import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["counter"]
  static values = {
    id: String,
    counter: Number
  }

  initialize() {
    this.counter = this.counterValue || 0
  }

  handleChange(event) {
    const { count, targetId } = event.detail

    if (targetId !== this.idValue) return

    this.counter = count
  }

  render() {
    this.counterTarget.innerText = this.formattedCounter
    this.element.style.display = this.counter === 0 ? "none" : "block"
  }

  set counter(value) {
    this._counter = value

    this.render()
  }

  get counter() {
    return this._counter
  }

  get formattedCounter() {
    if (this.counter === 0) {
      return ""
    }

    if (this.counter > 99) {
      return "99+"
    }

    return this.counter
  }
}
