import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    selector: String,
    focusOnConnect: Boolean,
    targetIsChild: Boolean // Let's us make the selector much simpler
  }

  connect() {
    if (this.focusOnConnectValue && this.targetElement) {
      this.focusTarget()
    }
  }

  focusTarget(_event) {
    if (!this.targetElement) { return }

    const el = this.targetElement
    el.focus()
    // This is to ensure that on focus the cursor is at the end of the text
    if (this.textInputType(el)) {
      const index = el.value.length
      el.setSelectionRange(index, index)
    }
  }

  textInputType(el) {
    return el.type === "text" || el.type === "textarea"
  }

  get targetElement() {
    const el = this.targetIsChildValue ? this.element : document
    return el.querySelector(this.selectorValue)
  }
}
