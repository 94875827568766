import { Controller } from "stimulus"
import anime from "animejs/lib/anime.es.js"

export default class extends Controller {
  static targets = ["closeProgress"]
  static values = {
    closeAfter: Number
  }

  renderer = {
    set: (target, property, value) => {
      target[property] = value

      if (property === "closed") {
        if (this.state.closed === true) {
          anime({
            targets: this.element,
            height: "0",
            paddingTop: "0",
            paddingBottom: "0",
            marginTop: "0",
            marginBottom: "0",
            opacity: "0",
            scale: 0.5,
            easing: "easeInOutQuad",
            duration: 250,
            complete: (animation) => {
              this.element.remove()
            }
          })
        }
      }

      return true
    }
  }

  initialize() {
    this.state = new Proxy({}, this.renderer)
    this.state.closed = false
    this.scheduleClose()
  }

  close(event) {
    if (event) event.preventDefault()

    this.state.closed = true
  }

  scheduleClose() {
    if (!this.hasCloseAfterValue) return

    setTimeout(() => this.close(), this.closeAfterValue)

    if (this.hasCloseProgressTarget) {
      const progressAnimationDuration = this.closeAfterValue / 1000
      this.closeProgressTarget.style.animationDuration = `${progressAnimationDuration}s`
    }
  }
}
