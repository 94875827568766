import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["priceWithoutTip"]

  updateTotal() {
    this.priceLabel = this.priceLabel.replace(this.moneyValueRegex, `<b> ${this.total()} </b>`)
  }

  total() {
    return event.target.attributes["data-total-with-tip"].value
  }

  get moneyValueRegex() {
    return /<b>(.*)<\/b>/
  }

  get priceLabel() {
    return document.getElementById("total-price").innerHTML
  }

  set priceLabel(text) {
    document.getElementById("total-price").innerHTML = text
  }
}
