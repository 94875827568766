import cable from "./action_cable"

function onCableConnected(controller) {
  if(controller.onCableConnected){
    controller.onCableConnected()
  }
}

function onCableDisconnected(controller, channelName) {
  const currentSubscription = loadSubscriptionIdentifier(controller, channelName)
  if (currentSubscription) {
    localStorage.removeItem("actionCableSubscriptions")
  }
  if(controller.onCableDisconnected){
    controller.onCableDisconnected()
  }
}

function onCableReceived(controller, data) {
  if(controller.onCableReceived){
    controller.onCableReceived(data)
  }
}

function persistSubscription(controller, channelName, subscription) {
  const currentStorage = JSON.parse(localStorage.getItem("actionCableSubscriptions")) || {}
  currentStorage[`${controller.identifier}-${channelName}`] = subscription.identifier
  localStorage.setItem("actionCableSubscriptions", JSON.stringify(currentStorage))
}

function loadSubscriptionIdentifier(controller, channelName) {
  const currentStorage = JSON.parse(localStorage.getItem("actionCableSubscriptions")) || {}
  return currentStorage[`${controller.identifier}-${channelName}`]
}

function removeSubscriptionIdentifier(controller, channelName) {
  const currentStorage = JSON.parse(localStorage.getItem("actionCableSubscriptions")) || {}
  delete currentStorage[`${controller.identifier}-${channelName}`]
  localStorage.setItem("actionCableSubscriptions", JSON.stringify(currentStorage))
}

function loadSubscription(controller, channelName) {
  const subscriptionIdentifier = loadSubscriptionIdentifier(controller, channelName)
  return cable.subscriptions.findAll(subscriptionIdentifier)[0]
}

function createSubscription(controller, channelName, subscriptionCallbacks) {
  let subscription = loadSubscription(controller, channelName)
  if(subscription){
    subscription.perform("load_unread_info")
    return subscription
  }

  subscription = cable.subscriptions.create(channelName, subscriptionCallbacks)
  persistSubscription(controller, channelName, subscription)
  return subscription
}

export function useActionCable(controller, options) {
  function subscribe(channelName) {
    createSubscription(controller, channelName, {
      connected: () => {
        onCableConnected(controller)
      },
      disconnected: () => {
        onCableDisconnected(controller, channelName)
      },
      received: (data) => {
        onCableReceived(controller, data)
      }
    })
  }

  function unsubscribe(channelName) {
    const subscription = loadSubscription(controller, channelName)

    if (subscription) {
      subscription.unsubscribe()
      removeSubscriptionIdentifier(controller, channelName)
    }
  }

  function unsubscribeAll() {
    const subscriptions = cable.subscriptions.subscriptions
    const subscriptionsToRemove = subscriptions.filter(subscription => subscription.identifier.includes(controller.identifier))
    subscriptionsToRemove.forEach(subscription => {
      const parsedIdentifier = JSON.parse(subscription.identifier)
      removeSubscriptionIdentifier(controller, parsedIdentifier.channel)
      subscription.unsubscribe()
    })
  }

  if (options && options.channel) {
    subscribe(options.channel)
  }

  return {
    subscribe,
    unsubscribe,
    unsubscribeAll
  }
}
