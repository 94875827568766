import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["hide", "show"]

  toggle(event) {
    event.preventDefault()

    this.hideTargets.forEach((target) => {
      target.classList.toggle("hidden")
    })

    this.showTargets.forEach((target) => {
      target.classList.toggle("hidden")
    })
  }
}
