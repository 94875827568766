import dialogPolyfill from "dialog-polyfill"
import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    displayAutomatically: Boolean,
    removeOnClose: Boolean
  }

  renderer = {
    set: (target, property, value) => {
      target[property] = value

      if (property === "presenting") this.renderer.toggle()
      if (property === "heightScale") this.renderer.resize()

      return true
    },

    toggle: () => {
      this.state.presenting ? this.renderer.present() : this.renderer.dismiss()
    },

    present: () => {
      this.element.showModal()
      this.element.addEventListener("cancel", event => event.preventDefault())

      document.body.classList.add("element--no-overflow")
    },

    dismiss: () => {
      // In older browsers, element.close() throws:
      //   Failed to execute 'close' on dialog: The element does not have an 'open' attribute,
      //   and therefore cannot be closed.
      // when the dialog does not exist on the page
      try {
        this.element.classList.add("dialog--closing")

        setTimeout(() => {
          this.element.classList.remove("dialog--closing")
          this.element.close()

          document.body.classList.remove("element--no-overflow")

          if (this.removeOnCloseValue) this.element.remove()

          this.emitDismissEvent()
        }, 500)
      } catch(error) {
        console.log(error.message)
      }
    },

    resize: () => {
      this.element.style.setProperty("--dialog-height-scale", `${this.state.heightScale}px`)
    }
  }

  connect() {
    this.state = new Proxy({}, this.renderer)

    dialogPolyfill.registerDialog(this.element)

    if (this.displayAutomaticallyValue) this.open()
  }

  disconnect() {
    this.close()
  }

  open(event) {
    if (event) event.preventDefault()

    this.state.presenting = true

    this.resize()
  }

  close(event) {
    if (event?.currentTarget?.getAttribute("href") === "#") event.preventDefault()

    this.state.presenting = false
  }

  resize(event) {
    this.state.heightScale = this.heightScale
  }

  emitDismissEvent() {
    const customEvent = new CustomEvent("dialog:dismiss", {
      bubbles: false,
      detail: this.state
    })
    this.element.dispatchEvent(customEvent)
  }

  get heightScale() {
    return window.innerHeight * 0.01
  }

  get backdropElement() {
    return document.querySelector(".dialog::backdrop") || document.querySelector(".dialog + .backdrop")
  }
}
