import { Controller } from "stimulus"

export default class extends Controller {
  static values = { collapsed: Boolean, scrollContainerSelector: String }

  connect() {
    const lists = this.element.querySelectorAll("ul")

    lists.forEach(list => list.classList.add("outline"))

    if (this.collapsedValue) {
      this.element.querySelectorAll("[data-action='outline#toggle']").forEach(toggle => {
        this.slideToggle(toggle, { duration: 0 })
      })
    }
  }

  toggle(event) {
    const element = event.currentTarget

    if (this.isTogglingWith(element)) {
      event.preventDefault()

      this.slideToggle(element)
    }
  }

  slideToggle(toggle, { duration = 200 } = {}) {
    const listItem = toggle.parentNode
    const list = listItem.querySelector("ul")

    if (!list) return

    if (list.classList.contains("outline--collapsed")) {
      toggle.classList.remove("outline__toggle--collapsed")
      list.classList.remove("outline--collapsed")
      listItem.classList.remove("outline__item--collapsed")
      global.helpers.slide.down(list, duration)

      this.scrollItemToTopOfSection(toggle)
    } else {
      toggle.classList.add("outline__toggle--collapsed")
      list.classList.add("outline--collapsed")
      listItem.classList.add("outline__item--collapsed")
      global.helpers.slide.up(list, duration)
    }
  }

  isTogglingWith(element) {
    return element.getAttribute("href") === "#"
  }

  scrollItemToTopOfSection(element) {
    if (!this.scrollContainer(element)) return

    // Calculate the position to scroll to
    const menuTop = this.scrollContainer(element).getBoundingClientRect().top
    const itemTop = element.getBoundingClientRect().top

    // Scroll the menu container to make the list item top aligned with the menu top
    this.scrollContainer(element).scrollBy({
      top: itemTop - menuTop,
      behavior: "smooth"
    })
  }

  scrollContainer(element) {
    if (!this.scrollContainerSelectorValue) return

    return element.closest(this.scrollContainerSelectorValue)
  }
}
