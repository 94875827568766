import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "item",
    "timepickerWrapper",
    "breakPanel",
    "togglePanel",

    "workFrom",
    "workTill",

    "breakFrom",
    "breakTill",
  ]

  static values = {
    open: Boolean,
    hasBreak: Boolean,

    allowBreaks: Boolean,

    openClass: String,
    hiddenClass: String,
  }

  connect() {
    if (this.openValue) {
      this.enableDay()

      if (this.hasBreakValue) {
        this.showBrakePanel()
      }
    } else {
      this.disableDay()
    }
  }

  toggleOpen() {
    if (this.openValue) {
      this.disableDay()
      this.openValue = false
    } else {
      this.enableDay()
      this.openValue = true
    }
  }

  hide(el) {
    el.classList.add(this.hiddenClassValue)
  }

  show(el) {
    el.classList.remove(this.hiddenClassValue)
  }

  enableDay() {
    this.itemTarget.classList.add(this.openClassValue)

    this.timepickerWrapperTargets.forEach(el => {
      this.show(el)
    })

    if (this.allowBreaksValue) {
      this.show(this.togglePanelTarget)
    }
  }

  disableDay() {
    this.itemTarget.classList.remove(this.openClassValue)

    this.timepickerWrapperTargets.forEach(el => {
      this.hide(el)
    })

    if (this.allowBreaksValue) {
      this.hide(this.togglePanelTarget)
    }

    if (this.hasBreakPanelTarget) {
      this.hide(this.breakPanelTarget)
    }
  }

  showBrakePanel(event) {
    if (event) event.preventDefault()

    if (this.hasTogglePanelTarget) { this.hide(this.togglePanelTarget) }
    this.show(this.breakPanelTarget)
    this.hasBreakValue = true
  }

  hideBrakePanel(event) {
    if (event) event.preventDefault()

    if (this.hasTogglePanelTarget) { this.show(this.togglePanelTarget) }
    this.hide(this.breakPanelTarget)
    this.hasBreakValue = false
  }
}
