import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "source" ]
  static values = {
    toggleClass: String,
    idTrigger: String
  }

  toggle() {
    if (event.currentTarget.getAttribute("id") === this.idTriggerValue) {
      this.sourceTarget.classList.remove(this.toggleClassValue)
    } else {
      this.sourceTarget.classList.add(this.toggleClassValue)
    }
  }
}
