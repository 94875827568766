import { Controller } from "stimulus"

export default class extends Controller {
  static values = { initialSection: String }
  static targets = ["tab", "section"]
  static classes = ["sectionToggle", "tabToggle"]

  renderer = {
    set: (target, property, value) => {
      target[property] = value

      this.renderer.toggleCurrentSection(property)

      return true
    },
    toggleCurrentSection: (property) => {
      if (property !== "currentSectionSelector") return

      this.sectionTargets.forEach((section) => section.classList.remove(this.sectionToggleSelector))
      this.tabTargets.forEach((tab) => tab.classList.remove(this.tabToggleSelector))

      this.currentSectionElement.classList.add(this.sectionToggleSelector)
      this.currentTabElement.classList.add(this.tabToggleSelector)
    }
  }

  initialize() {
    this.state = new Proxy({}, this.renderer)
    if (this.hasInitialSectionValue) {
      this.state.currentSectionSelector = this.initialSectionValue
    }
  }

  show(event) {
    event.preventDefault()
    this.state.currentSectionSelector = event.currentTarget.getAttribute("href")
  }

  get currentSectionElement() {
    return this.sectionTargets.find((el) => el.matches(this.state.currentSectionSelector))
  }

  get currentTabElement() {
    return this.tabTargets.find((el) => el.matches(`a[href="${this.state.currentSectionSelector}"]`))
  }

  get sectionToggleSelector() {
    if (this.hasSectionToggleClass) {
      return this.sectionToggleClass
    } else {
      return "element--on"
    }
  }

  get tabToggleSelector() {
    if (this.hasTabToggleClass) {
      return this.tabToggleClass
    } else {
      return "tab__link--active"
    }
  }
}
