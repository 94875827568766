import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["option", "selectedCount", "selectedCountContainer", "selectedTagTemplate", "selectedTags", "emptySearch", "select"]
  static classes = ["optionHidden"]

  connect() {
    this.optionTargets.forEach(el => {
      this.updateSelectedOption(this.checkboxFor(el))
    })
  }

  search(event) {
    const text = event.target.value.toLocaleLowerCase().trim()
    let empty = true

    this.optionTargets.forEach(el => {
      if (text === "" || this.labelFor(el).toLocaleLowerCase().search(text) !== -1) {
        el.classList.remove(this.optionHiddenClass)
        empty = false
      } else {
        el.classList.add(this.optionHiddenClass)
      }
    })

    if (this.hasEmptySearchTarget) {
      if (empty) {
        this.emptySearchTarget.style.display = "flex"
      } else {
        this.emptySearchTarget.style.display = "none"
      }
    }
  }

  toggleOptionSelection(event) {
    const checkbox = event.target
    this.updateSelectedOption(checkbox)
  }

  removeTag(event) {
    // Can use this since, tags are not something that will be created differently for different types of options
    const { selectFieldId } = event.target.parentElement.dataset
    const el = this.optionTargets.find(el => el.dataset.selectFieldId === selectFieldId)
    if (el) {
      this.checkboxFor(el).click()
    }
  }

  // count the number of selected options and update the selected count
  updateSelectedCount(checked) {
    const selectedOptions = this.optionTargets.filter(el => this.checkboxFor(el).checked)
    const newCount = selectedOptions.length
    if (newCount === 0) {
      this.selectedCountTarget.innerText = this.selectedCountTarget.dataset.selectedCountMessageZero
    } else if (newCount === 1) {
      this.selectedCountTarget.innerText = this.selectedCountTarget.dataset.selectedCountMessageOne
    } else {
      this.selectedCountTarget.innerText = this.selectedCountTarget.dataset.selectedCountMessageOther.replace("%{count}", newCount)
    }

    if (newCount > 0) {
      this.selectedCountContainerTarget.style.display = "flex"
    } else {
      this.selectedCountContainerTarget.style.display = "none"
    }
  }

  updateSelectedTag(checkbox) {
    const { checked } = checkbox
    const { selectFieldId } = this.elementFor(checkbox).dataset
    if (checked) {
      const node = this.selectedTagTemplateTarget.content.cloneNode(true)
      const tag = node.firstElementChild
      tag.dataset.selectFieldId = selectFieldId

      const textNode = tag.querySelector(".text")
      textNode.innerText = this.labelFor(this.elementFor(checkbox))

      this.tagsRowThree.appendChild(node)
    } else {
      outer:
      for (const container of this.selectedTagsTargets) {
        for (const el of container.children) {
          if (el.dataset.selectFieldId === selectFieldId) {
            el.remove()
            break outer
          }
        }
      }
    }

    this.arrangeSelectedTags()
  }

  updateSelectedOption(checkbox) {
    // This might need to be updated if we want to create an option component which simply cannot
    // conform to the convention of having the value in the checkbox field
    const { checked, value } = checkbox
    this.selectTarget.querySelector(`option[value="${value}"]`).selected = checked

    if (this.hasSelectedCountTarget) {
      this.updateSelectedCount(checkbox.checked)
    }
    if (this.hasSelectedTagsTarget) {
      this.updateSelectedTag(checkbox)
    }
  }

  arrangeSelectedTags() {
    this.tagsRowOne.style.display = "none"
    this.tagsRowTwo.style.display = "none"
    this.tagsRowThree.style.display = "none"

    if (this.selectedTagsTargets.length === 0) {
      return
    }

    const tags = this.selectedTagsTargets.reduce((acc, parent) => {
      for (const el of parent.children) {
        acc.push(el)
      }
      return acc
    }, [])

    let limit = 5
    let numRows = 1

    if (this.isMobile) {
      limit = 3
    }

    this.tagsRowOne.style.display = "flex"

    if (tags.length > limit) {
      this.tagsRowTwo.style.display = "flex"
      numRows = 2
    }

    if (tags.length > (limit * 2)) {
      this.tagsRowThree.style.display = "flex"
      numRows = 3
    }

    const hasThirdRow = numRows > 2

    if (numRows === 1) {
      tags.forEach(el => this.tagsRowOne.appendChild(el))
    } else {
      const rowTwoStart = Math.floor(tags.length / numRows)
      // This is required so that we evenly divide the tags
      const halfwayBetweenRemainingTags = Math.floor((tags.length - rowTwoStart) / 2)
      const rowThreeStart = hasThirdRow ? (rowTwoStart + halfwayBetweenRemainingTags) : tags.length
      tags.forEach((el, i) => {
        if (i < rowTwoStart) {
          this.tagsRowOne.appendChild(el)
        } else if (i < rowThreeStart) {
          this.tagsRowTwo.appendChild(el)
        } else {
          this.tagsRowThree.appendChild(el)
        }
      })
    }
  }

  labelFor(el) {
    return el.dataset.selectFieldLabel
  }

  checkboxFor(el) {
    return el.querySelector("input[type=\"checkbox\"]")
  }

  elementFor(checkbox) {
    return checkbox.parentElement
  }

  get tagsRowOne() {
    return this.selectedTagsTargets[0]
  }

  get tagsRowTwo() {
    return this.selectedTagsTargets[1]
  }

  get tagsRowThree() {
    return this.selectedTagsTargets[2]
  }

  get isMobile() {
    return this.element.offsetWidth <= 420
  }
}
