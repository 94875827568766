import { Controller } from "stimulus"

export default class extends Controller {
  static values = { extraSubmitParams: Object }

  static targets = [
    "form"
  ]

  connect() {
    this.setParamAfterHours()
  }

  setParamAfterHours() {
    if(!window.location.href.includes("calendar")) { return }
    if(!this.formTarget || !this.extraSubmitParamsValue) { return }

    const formUrl = new URL(this.formTarget.action, global.location.origin)
    const newParams = this.extraSubmitParamsValue

    for (const key in newParams) {
      formUrl.searchParams.append(key, newParams[key])
    }

    this.formTarget.action = formUrl.toString()
  }
}
