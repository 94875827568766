class CalendarDataSource {
  constructor({ date, days, page = 0 }) {
    this.date = date
    this.days = days
    this.page = page
  }

  range() {
    const range = global.moment().range(this.rangeStart(), this.rangeEnd())
    let section = "previous"

    return Array.from(range.by("days")).map(day => {
      const isCurrent = day.within(this.currentRange())

      if (isCurrent) section = "current"
      if (section === "current" && !isCurrent) section = "next"

      return { moment: day, section: section }
    })
  }

  startDate() {
    return global.moment(this.date).add(this.days * this.page, "day")
  }

  endDate() {
    return global.moment(this.startDate()).add(Math.max(this.days - 1, 0), "day")
  }

  rangeStart() {
    return this.startDate().subtract(this.days, "day")
  }

  rangeEnd() {
    return this.endDate().add(this.days, "day")
  }

  currentRange() {
    return global.moment.range(this.startDate(), this.endDate())
  }

  get dateFormat() {
    return "YYYY-MM-DD"
  }
}

export default CalendarDataSource
