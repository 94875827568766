import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["dateText"]

  renderer = {
    set: (target, property, value) => {
      target[property] = value

      if (property === "selection") this.renderer.printText()

      return true
    },

    printText: () => {
      if (this.state.selection === null) return

      const start = global.moment(this.state.selection.start).format("LL")
      const end = global.moment(this.state.selection.end).format("LL")

      if (start === end) {
        this.dateTextTarget.innerText = start

        return
      }

      this.dateTextTarget.innerText = `${start} - ${end}`
    }
  }

  connect() {
    this.state = new Proxy({}, this.renderer)
    this.state.selection = null
  }

  select(event) {
    this.state.selection = event.detail.selection
  }
}
