import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "contentToReplace" ]

  load() {
    Rails.ajax({
      type: "get",
      url: this.data.get("url"),
      dataType: "script"
    })
  }

  fetch() {
    if (this.hasContentToReplaceTarget && (this.isVisible(this.contentToReplaceTarget) === true)) {
      this.load()
      this.contentToReplaceTarget.remove() //to stop extra requests
    }
  }

  isVisible(ele) {
    const { top, bottom } = ele.getBoundingClientRect()
    const vHeight = (window.innerHeight || document.documentElement.clientHeight)

    return (
      (top > 0 || bottom > 0) &&
      top < vHeight
    )
  }
}
