import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    vapidPublicKey: String
  }

  connect() {
    this.registrationHandlerBound = this.handleRegistrationEvent.bind(this)
    window.addEventListener("serviceworker:register", this.registrationHandlerBound)
    this.startRegistration(
      { scope: "/" },
      registration => {
        this.handleRegistration(registration)
      },
      error => console.error("Service Worker registration failed:", error)
    )
  }

  disconnect() {
    window.removeEventListener("serviceworker:register", this.registrationHandlerBound)
  }


  // Handle service worker registration through dispatched event
  handleRegistrationEvent = (event) => {
    const { registrationDetails, onSuccess, onError } = event.detail

    this.startRegistration(registrationDetails, onSuccess, onError)
  }

  startRegistration(registrationDetails, onSuccess, onError) {
    if (!("serviceWorker" in navigator)) {
      console.error("Service Worker not supported")
      return
    }

    navigator.serviceWorker.register("/service-worker.js", registrationDetails)
      .then((registration) => {
        onSuccess(registration)
      })
      .catch((error) => {
        this.onError(error)
      })
  }

  handleRegistration(registration) {
    return Promise.all([
      this.handleNotificationSubscription(registration),
      this.registerBackgroundSync(registration)
    ])
  }

  handleNotificationSubscription(registration) {
    return Notification.requestPermission()
      .then(permission => {
        if (permission !== "granted") {
          console.error("Notification permission denied")
          return Promise.reject("Notification permission denied")
        }
        return navigator.serviceWorker.ready
      })
      .then(registration => registration.pushManager.getSubscription())
      .then(subscription => {
        const subscribedInServer = window.localStorage.getItem("push-notifications-subscribed-in-server")
        if (subscription && subscribedInServer) {
          return Promise.resolve(subscription)
        }
        return this.subscribeToPushNotifications(registration)
      })
      .catch(error => console.error("Subscription flow failed:", error))
  }

  subscribeToPushNotifications(registration) {
    return registration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: window.helpers.urlBase64ToUint8Array(this.vapidPublicKeyValue)
    })
    .then(subscription => {
      this.sendSubscriptionToServer(subscription)
    })
    .catch(error => console.error("PushManager Subscription failed:", error))
  }

  sendSubscriptionToServer(subscription) {
    const body = JSON.stringify(subscription)
    const csrfToken = document.querySelector("meta[name=\"csrf-token\"]").content
    const headers = {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken
    }
    return fetch("/service-worker/subscribe", { method: "POST", headers, body })
      .then(response => {
        if (response.ok) {
          window.localStorage.setItem("push-notifications-subscribed-in-server", true)
        } else {
          console.error("Failed Subscription. Bad status code from server:", response.status)
        }
      })
  }

  registerBackgroundSync(registration) {
    if (!("SyncManager" in window)) {
      return Promise.reject("SyncManager not supported")
    }

    return navigator.serviceWorker.ready
      .then(registration => registration.sync.getTags())
      .then(tags => {
        if (tags.includes("nearcut-sw")) {
          return Promise.resolve()
        }
        return this.registerSync(registration)
      })
      .catch(error => console.error("Background Sync registration failed:", error))
  }

  registerSync(registration) {
    return registration.sync.register("nearcut-sw")
      .then(() => {
        console.log("Background Sync registered")
      })
      .catch(error => console.error("Background Sync registration failed:", error))
  }
}
